<template>
  <div>
    <Toast />
    <div class="grid h-screen m-0">
      <div class="col hidden md:block bg-primary bg-login"></div>

      <div class="col">
        <div
          class="
            flex flex-column
            align-items-center
            justify-content-center
            h-full
          "
        >
          <div class="text-center mb-5">
            <div class="mb-3">
              <img
                src="images/logo-dark.svg"
                alt="Image"
                height="45"
                class="mb-3"
              />
            </div>
            <span class="text-600 font-medium line-height-3"
              >Ainda não tem cadastro?
            </span>
            <a class="font-medium no-underline ml-2 cursor-pointer"
              >Cadastre-se aqui!</a
            >
          </div>

          <div class="lg:w-6 w-10">
            <form @submit.prevent="login">
              <label for="email1" class="block text-900 font-medium mb-2"
                >Email</label
              >
              <InputText
                v-model="username"
                id="email1"
                type="text"
                class="w-full mb-3"
              />

              <label for="password1" class="block text-900 font-medium mb-2"
                >Senha</label
              >
              <InputText
                v-model="password"
                id="password1"
                type="password"
                class="w-full mb-3"
              />

              <div class="flex align-items-center justify-content-between mb-6">
                <div class="flex align-items-center">
                  <Checkbox
                    id="rememberme1"
                    :binary="true"
                    v-model="checked"
                    class="mr-2"
                  ></Checkbox>
                  <label for="rememberme1">Lembrar</label>
                </div>
                <a
                  class="
                    font-medium
                    no-underline
                    ml-2
                    text-right
                    cursor-pointer
                  "
                  >Esqueceu a senha?</a
                >
              </div>

              <Button
                type="submit"
                label="Acessar"
                icon="pi pi-user"
                class="w-full bg-primary border-primary"
              ></Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/services/auth'

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      checked: ''
    }
  },
  methods: {
    async login() {
      try {
        await login(this.username, this.password)
        this.$router.replace(this.$route.query.redirect || '/')
      } catch ({ response }) {
        const { data } = response
        //this.$noty.error(data.message)
        this.$toast.add({
          severity: 'error',
          summary: data.message,
          life: 3000
        })
      }
    }
  }
}
</script>

<style scoped>

.bg-login{
  background-image: url('~@/assets/img/hero-image.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
